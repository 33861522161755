<template>
  <div class="app-container">
    <div class="top_box">
      <div>
        <el-form :inline="true" class="demo-form-inline account-top">
          <el-form-item>
            <el-input
              v-model="user_id"
              placeholder="请输入账号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              size="mini"
              @click="addTask"
              type="success"
              v-loading="listLoading"
            >
              提交
            </el-button>
          </el-form-item>

          <el-form-item>
            <el-button
              size="mini"
              type="primary"
              @click="fetchData"
              v-loading="listLoading"
            >
              刷新
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="bottom_box">
      <div class="content_t">
        <el-table
          v-loading="listLoading"
          :data="list"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          class="tableList2"
          :header-cell-style="{ background: '#F3F7F9', color: '#909399' }"
          height="100%"
          style="width: 100%"
          algin="center"
        >
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <!-- <el-table-column prop="user_id" label="用户ID"></el-table-column> -->
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column prop="state" label="任务状态">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                :content="getStatusTip(scope.row.state, statusMap)"
                placement="top"
              >
                <el-tag
                  :type="getStatusType(scope.row.state, statusMap)"
                  disable-transitions
                  size="small"
                >
                  {{ getStatusLabel(scope.row.state, statusMap) }}
                </el-tag>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column prop="emails" label="邮箱"></el-table-column>
          <el-table-column prop="owner" label="查询者" v-if="userInfo.is_staff">
            <template slot-scope="scope">
              {{
                scope.row.owner
                  ? scope.row.owner.oauth_account
                    ? scope.row.owner.oauth_account
                    : scope.row.owner.username
                  : null
              }}
            </template>
          </el-table-column>
          <el-table-column label="查询者备注" v-if="userInfo.is_staff">
            <template slot-scope="scope">
              {{ scope.row.owner ? scope.row.owner.note : null }}
            </template>
          </el-table-column>

          <el-table-column
            prop="create_time"
            label="创建时间"
          ></el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                v-if="scope.row.user"
                size="small"
                @click="detailFun(scope.row.user ? scope.row.user : {})"
              >
                用户资料
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.page_index"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryForm.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      ></el-pagination>
    </div>
    <el-dialog :visible.sync="dialogVisible" title="详情" width="30%">
      <el-descriptions :column="2">
        <el-descriptions-item label="用户ID">
          {{ userDetail.user_id }}
        </el-descriptions-item>
        <el-descriptions-item label="用户名">
          {{ userDetail.username }}
        </el-descriptions-item>

        <el-descriptions-item label="头像">
          <img
            class="table-img"
            referrerpolicy="no-referrer"
            :src="
              userDetail.avatar_url
                ? userDetail.avatar_url
                : require('@/assets/imgs/i12.png')
            "
            alt=""
          />
        </el-descriptions-item>

        <el-descriptions-item label="网站管理员">
          <span v-if="userDetail.site_admin">
            {{ userDetail.site_admin ? '是' : '否' }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="昵称">
          {{ userDetail.name }}
        </el-descriptions-item>
        <el-descriptions-item label="公司">
          {{ userDetail.company }}
        </el-descriptions-item>
        <el-descriptions-item label="博客">
          <a
            v-if="userDetail.blog"
            style="color: blue"
            :href="userDetail.blog"
            target="_blank"
          >
            点击查看
          </a>
        </el-descriptions-item>
        <el-descriptions-item label="所在地">
          {{ userDetail.location }}
        </el-descriptions-item>
        <el-descriptions-item label="邮箱">
          {{ userDetail.emails }}
        </el-descriptions-item>
        <el-descriptions-item label="个人简介">
          {{ userDetail.bio }}
        </el-descriptions-item>

        <el-descriptions-item label="twitter用户名">
          {{ userDetail.twitter_username }}
        </el-descriptions-item>
        <el-descriptions-item label="公开仓库数">
          {{ userDetail.public_repos }}
        </el-descriptions-item>
        <el-descriptions-item label="粉丝数">
          {{ userDetail.followers }}
        </el-descriptions-item>
        <el-descriptions-item label="关注数">
          {{ userDetail.following }}
        </el-descriptions-item>
        <el-descriptions-item label="账号创建时间">
          {{ userDetail.created_at }}
        </el-descriptions-item>
        <el-descriptions-item label="账号更新时间">
          {{ userDetail.updated_at }}
        </el-descriptions-item>
        <el-descriptions-item label="数据入库时间">
          {{ userDetail.create_time }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { getAccountTask, addAccountTask } from '@/api/detect'
import { getManagerStatus } from '@/utils/manager'
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'

export default {
  components: {},
  data() {
    return {
      userInfo: this.$store.state.user.userInfo || {},
      list: null,
      listLoading: false,
      count: 0,
      isManager: getManagerStatus(),
      queryForm: {
        page_index: 1,
        page_size: 10,
        search: ''
      },
      user_id: null,
      showDialog: false,
      statusMap: {
        3: {
          type: 'info',
          label: '帐号不存在'
        },
        2: {
          type: 'info',
          label: '无结果'
        },
        1: {
          type: 'success',
          label: '查询成功'
        },
        0: {
          type: 'primary',
          label: '待执行'
        },
        '-1': {
          type: 'danger',
          label: '执行失败'
        }
      },
      // 详情弹框
      dialogVisible: false,
      userDetail: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    // 详情事件
    detailFun(row) {
      this.userDetail = Object.assign({}, row)

      this.dialogVisible = true
    },
    //
    getStatusType,
    getStatusLabel,
    getStatusTip,
    async fetchData() {
      this.listLoading = true
      const { count, items } = await getAccountTask(this.queryForm)
      this.count = count
      this.list = items
      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.page_index = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.page_size = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.page_index = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    async addTask() {
      this.listLoading = true
      try {
        await addAccountTask({
          username: this.user_id
        })
        this.fetchData()
      } finally {
        this.user_id = null
        this.listLoading = false
      }
    },
    receiveVal(val) {
      this.showDialog = val
      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
.top_box {
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  button.el-button.el-button--primary {
    height: 32px;
    padding: 0 20px;
    // line-height: 32px;
  }
  button.el-button.el-button--success.el-button--mini {
    height: 32px;
    padding: 0 20px;
  }
  .el-form-item {
    margin-bottom: 0;
    .el-input {
      padding: 0 15px;
      width: 250px;
      ::v-deep span.el-input__suffix {
        right: 20px;
      }
    }
    ::v-deep input.el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}

.bottom_box {
  width: 100%;
  height: calc(100vh - 178px);
  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 50px);
    margin-top: 10px;
    // overflow: auto;
    ::v-deep td.el-table_1_column_1.el-table__cell {
      text-align: center;
    }
  }
}
.table-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
::v-deep .el-button--mini {
  padding: 9px 15px;
}
</style>
